import ParkingSelector from "components/ParkingSelector";
import Welcomegreet from "components/Welcomegreet";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import toastr from "toastr";
import { connect } from "react-redux";
import {
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Container,
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import {
  addSensorRequest,
  updateSensorRequest,
  getSensorRequest,
  getCompaniesRequest,
  getCitiesRequest,
  getCountriesRequest,
  getZonesRequest,
  getParkingStructuresRequest,
} from "store/actions";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const getCountryAbbrById = (countries, id) => {
  console.log("countries", countries, "id", id);
  return countries?.find((item) => item?._id === id)?.country_abbreviation;
};

const getCityAbbrById = (cities, id) => {
  return cities?.find((item) => item?._id === id)?.city_abbreviation;
};
const getCompanyAbbrById = (companies, id) => {
  return companies?.find((item) => item?._id === id)?.company_abbreviation;
};

const getZoneNumberById = (zones, id) => {
  try {
    return zones?.length
      ? zones?.find((item) => item?._id === id)?.zone_number?.toString()
      : null;
  } catch (e) {
    console.log(e);
  }
};

const getParkingById = (parkings, id) => {
  return parkings?.find((item) => item?._id === id);
};

function Addcensor(props) {
  const {
    user,
    addSensorRequest,
    loading,
    companies,
    countries,
    cities,
    zones,
    getSensorRequest,
    updateSensorRequest,
    singleSensorDetails,
    addUpdateSensorLoading,
    getCompaniesRequest,
    getCitiesRequest,
    getCountriesRequest,
    getZonesRequest,
    getParkingStructuresRequest,
    parkingStructures,
  } = props;
  console.log("parkingStructures", parkingStructures);
  const { id } = useParams();
  const [fields, setFields] = useState({
    countryId: "",
    companyId: "",
    cityId: "",
    zoneId: "",
    carpark_id: "",
    carParkFloor: "",
    carParkSlotNumber: "",
    parkingId: null,
    // carParkSlotStatus: "FREE",
    status: "FREE",
    carpark_code: "",
    latitude: "",
    longitude: "",
    location_name:"",
  });
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedParking, setSelectedParking] = useState(null);

  useEffect(() => {
    if (id) {
      getSensorRequest(id);
    }
    getCompaniesRequest({
      orderBy: "createdAt",
      order: -1,
      page: 1,
      limit: 100,
    });
    getCountriesRequest({
      orderBy: "createdAt",
      order: -1,
      page: 1,
      limit: 100,
    });
    getCitiesRequest({
      orderBy: "createdAt",
      order: -1,
      page: 1,
      limit: 100,
    });
    getZonesRequest({
      orderBy: "createdAt",
      order: -1,
      page: 1,
      limit: 100,
    });
  }, [id]);

  useEffect(() => {
    if (fields.parkingId) {
      setSelectedParking(
        parkingStructures.find((data) => data._id == fields.parkingId)
      );
    }
  }, [fields.parkingId]);


  // useEffect(() => {
  //   if (user?._id) {
  //     getParkingStructuresRequest({ page: 1, limit: 10, owner_id: user?._id });
  //   }
  // }, [user]);

  useEffect(() => {
    if (singleSensorDetails && id) {
      try {
        setFields({
          countryId: singleSensorDetails?.countryId?._id || "",
          companyId: singleSensorDetails?.companyId?._id || "",
          cityId: singleSensorDetails?.cityId?._id || "",
          zoneId: singleSensorDetails?.zoneId?._id || "",
          carpark_id: singleSensorDetails?.carpark_id || "",
          carParkFloor: singleSensorDetails?.carParkFloor || "",
          carParkSlotNumber: singleSensorDetails?.carParkSlotNumber || "",
          parkingId: singleSensorDetails?.parkingId?._id || "",
          status: singleSensorDetails?.status || "FREE",
          carpark_code: singleSensorDetails?.carpark_code,
          latitude: singleSensorDetails?.gpsCoordinates?.latitude || "",
          longitude: singleSensorDetails?.gpsCoordinates?.longitude || "",
          location_name: singleSensorDetails?.location_name || "",
        });
        setSelectedCollection([
          {
            value: singleSensorDetails?.parkingId?._id,
            label: singleSensorDetails?.parkingId?.building_name,
          },
        ]);
      } catch (err) {
        console.log("parsing error ", err);
      }
    }
  }, [singleSensorDetails]);

  const onFieldsChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    setFields({ ...fields, [name]: value });
  };

  // const onChangeSensorInfo = (e) =>{
  //   const {name,value} = e.target;
  //   // serial_number
  //       setFields((pre)=>({...pre, sensor_info:{[name] : value}}))
  // }

  const onChangeCollectionFilter = (data) => {
    setFields((prev) => ({ ...prev, parkingId: data?.value }));
    setSelectedCollection(data);
  };

  const geocodeAddress = async (lat, lng) => {
    // Define the LatLng object with the provided latitude and longitude
    try {
      let getAddress = await axios.get(
        ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_APIKEY}`
      );

      return getAddress.data;
    } catch (error) {
      return false;
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    let payload = { ...fields };
    // const carpark_code = `${getCompanyAbbrById(companies, fields?.companyId)}-${getParkingById(parkingStructures, fields?.parkingId)?.parkingNumber
    //   }-${getZoneNumberById(zones, fields?.zoneId)}`;

    if (selectedParking?.type == "Street") {
      payload.gpsCoordinates = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
    }
    if (id) {
      updateSensorRequest(
        { ...payload, id: singleSensorDetails?._id },
        () => {
          props.history.push("/sensors");
        }
      );
    } else {
      addSensorRequest({ ...payload }, () => {
        props.history.push("/sensors");
      });
    }
  };


  const onSelectGeoLocation = async (data) => {
    try {
      // console.log(data, "results")
      const results = await geocodeByPlaceId(data?.value?.place_id);
      const latLng = await getLatLng(results[0]);
      const location_name = results?.[0]?.formatted_address;
      const lat = latLng.lat;
      const lng = latLng.lng;
      console.log("location", location_name);
      setFields((prev) => ({ ...prev, location_name, latitude:lat, longitude:lng }));
    } catch (error) {
      console.log("Error geocoding address:", error);
    }
  };

  return (
    <section className="add_cesormain">
      <div className="page-content">
        <Welcomegreet />
        <Container fluid>
          <a
            href="javascript:void(0)"
            className="backto_censor"
            onClick={() => props.history.push("/sensors")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                fill="#2E3A59"
              />
            </svg>
            {id ? props.t("update") : props.t("add_slots")}
          </a>
          <Form className="add_form" onSubmit={onSubmitForm}>
            <div className="country_fields">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("country")}</Label>
                    <Input
                      type="select"
                      name="countryId"
                      value={fields.countryId}
                      id="exampleEmail"
                      // placeholder="BG"
                      onChange={onFieldsChange}
                      required
                    >
                      <option value="">{props.t("select")}</option>
                      {countries?.map((item) => {
                        return (
                          <option value={item?._id}>
                            {item?.country_name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("city")}</Label>
                    <Input
                      type="select"
                      name="cityId"
                      value={fields.cityId}
                      id="exampleEmail"
                      // placeholder="BG"
                      onChange={onFieldsChange}
                      required
                    >
                      <option value="">{props.t("select")}</option>
                      {cities?.map((item) => {
                        return (
                          <option value={item?._id}>{item?.city_name}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("company")}</Label>
                    <Input
                      type="select"
                      name="companyId"
                      value={fields.companyId}
                      id="exampleEmail"
                      // placeholder="BG"
                      onChange={onFieldsChange}
                      required
                    >
                      <option value="">{props.t("select")}</option>
                      {companies?.map((item) => {
                        return (
                          <option value={item?._id}>
                            {item?.company_name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("zone")}</Label>
                    <Input
                      type="select"
                      name="zoneId"
                      value={fields.zoneId}
                      id="exampleEmail"
                      // placeholder="BG"
                      onChange={onFieldsChange}
                      required
                    >
                      <option value="">{props.t("select")}</option>
                      {zones?.map((item) => {
                        return (
                          <option value={item?._id}>{item?.zone_name}</option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("sensor_number")}</Label>
                    <Input
                      type="text"
                      name="number"
                      value={fields.number}
                      id="exampleEmail"
                      placeholder={props.t("sensor_number")}
                      onChange={onFieldsChange}
                    // required
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("parking")}</Label>
                    {/* <Input
                    type="select"
                    name="parkingId"
                    id="exampleEmail"
                    value={fields.parkingId}
                    // placeholder="BG"
                    onChange={onFieldsChange}
                  /> */}
                    <ParkingSelector
                      placeholder="select parking"
                      isMulti={false}
                      defaultSelected={selectedCollection}
                      onChangeHandler={onChangeCollectionFilter}
                    />
                  </FormGroup>
                </Col>
                {selectedParking?.type == "Street" && (
                  <>
                    {/* <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">{props.t("location")}</Label>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_APIKEY || ""}
                        selectProps={{
                          placeholder: fields.location_name || "",
                          onChange: onSelectGeoLocation,
                          onSelect: true,
                        }}
                        onLoadFailed={(error) => {
                          console.log(error);
                        }}
                      />
                    </FormGroup>
                  </Col> */}
                     {selectedParking?.type == "Street" && (
                  <>
                    {/* <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="exampleEmail">{props.t("location")}</Label>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_APIKEY || ""}
                        selectProps={{
                          placeholder: fields.location_name || "",
                          onChange: onSelectGeoLocation,
                          onSelect: true,
                        }}
                        onLoadFailed={(error) => {
                          console.log(error);
                        }}
                      />
                    </FormGroup>
                  </Col> */}
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{props.t("latitude")}</Label>
                        <Input
                          type="text"
                          value={fields.latitude}
                          name="latitude"
                          id="exampleEmail"
                          // placeholder="BG"
                          onChange={onFieldsChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{props.t("longitude")}</Label>
                        <Input
                          type="text"
                          value={fields.longitude}
                          name="longitude"
                          id="exampleEmail"
                          // placeholder="BG"
                          onChange={onFieldsChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}
                  </>
                )}
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("car_park_floor")}
                    </Label>
                    <Input
                      type="number"
                      name="carParkFloor"
                      id="exampleEmail"
                      value={fields.carParkFloor}
                      placeholder={props.t("car_park_floor")}
                      onChange={onFieldsChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("car_park_slot_number")}
                    </Label>
                    <Input
                      type="text"
                      name="carParkSlotNumber"
                      id="exampleEmail"
                      value={fields.carParkSlotNumber}
                      placeholder={props.t("car_park_slot_number")}
                      onChange={onFieldsChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("car_park_slot_status")}
                    </Label>
                    <Input
                      type="select"
                      name="carParkSlotStatus"
                      id="exampleEmail"
                      // placeholder="BG"
                      value={fields.status}
                      onChange={onFieldsChange}
                    >
                      <option value="FREE">FREE</option>
                      <option value="BUSY">BUSY</option>
                      <option value="NOT_CALIBRATED">NOT_CALIBRATED</option>
                    </Input>
                  </FormGroup>
                </Col>

                {/* <Col md={6}>
                  <FormGroup>
                    <Label>{props.t("status")}</Label>

                    <div className="status-switch square-switch">
                      <input
                        type="checkbox"
                        id="square-switch1"
                        switch="none"
                        name="idle"
                        checked={fields.status === "active" ? true : false}
                        onChange={() => {
                          let value =
                            fields.idle === "active" ? "inactive" : "active";
                          onFieldsChange({
                            target: { name: "status", value: value },
                          });
                        }}
                      />
                      <label
                        htmlFor="square-switch1"
                        data-on-label={props.t("active")}
                        data-off-label={props.t("inactive")}
                      />
                    </div>
                  </FormGroup>
                </Col> */}
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("carpark_id")}</Label>
                    <Input
                      type="text"
                      name="carpark_id"
                      id="exampleEmail"
                      value={fields?.carpark_id}
                      placeholder={props.t("carpark_id")}
                      onChange={onFieldsChange}

                    />
                    {/* <p>{`${getCompanyAbbrById(companies, fields?.companyId) || "NA"
                      }-${getParkingById(parkingStructures, fields?.parkingId)
                        ?.parkingNumber || "NA"
                      }-${getZoneNumberById(zones, fields?.zoneId)}`}</p> */}
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <Button type="submit" className="btn_addform mt-5">
              {addUpdateSensorLoading ? (
                <Spinner size="sm" type="light" />
              ) : null}
              {id ? props.t("update") : props.t("add")}
            </Button>
          </Form>
        </Container>
      </div>
    </section>
  );
}

const mapStateToProps = ({ Sensors, Dashboard, Parkings, Login }) => ({
  user: Login.user,
  loading: Sensors.loading,
  singleSensorDetails: Sensors.singleSensorDetails,
  addUpdateSensorLoading: Sensors.addUpdateSensorLoading,
  companies: Dashboard.companies,
  countries: Dashboard.countries,
  cities: Dashboard.cities,
  zones: Dashboard.zones,
  parkingStructures: Parkings.parkingStructures,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addSensorRequest: (data, callback) => {
      dispatch(addSensorRequest(data, callback));
    },
    getSensorRequest: (data, callback) => {
      dispatch(getSensorRequest(data, callback));
    },
    updateSensorRequest: (data, callback) => {
      dispatch(updateSensorRequest(data, callback));
    },
    getCompaniesRequest: (data, callback) => {
      dispatch(getCompaniesRequest(data, callback));
    },
    getCountriesRequest: (data, callback) => {
      dispatch(getCountriesRequest(data, callback));
    },
    getCitiesRequest: (data, callback) => {
      dispatch(getCitiesRequest(data, callback));
    },
    getZonesRequest: (data, callback) => {
      dispatch(getZonesRequest(data, callback));
    },
    getParkingStructuresRequest: (data, callback) => {
      dispatch(getParkingStructuresRequest(data, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Addcensor))
);
